<template>
  <div class="merchodrlines">
    <div v-if="disputeOder.order_id" class="panel-body" style="padding-bottom:0px;">
      <div class="row">
      
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 customer-details-block">
            <h4>Customer Details:</h4>
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                    Customer :
                    {{ disputeOder.order_id.shipping_name != undefined ? disputeOder.order_id.shipping_name : "" }}
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                    <div style="display: inline-block; width: 100%;">
                        Address :
                        {{ " " + disputeOder.order_id.address_id.address_line_1 != undefined ? disputeOder.order_id.address_id.address_line_1 + "," : ""}}
                        {{ disputeOder.order_id.address_id.address_line_2 != undefined ? disputeOder.order_id.address_id.address_line_2 + ",": "" }}
                        {{ disputeOder.order_id.address_id.city.city_name != undefined ? disputeOder.order_id.address_id.city.city_name + ",": "" }}
                        {{ disputeOder.order_id.address_id.country.country_name != undefined ? disputeOder.order_id.address_id.country.country_name : "" }}
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12" v-if="disputeOder.order_id && disputeOder.order_id.hasOwnProperty('payment_type')">{{ 
                    disputeOder.order_id.payment_type === 1 ? "Payment Method : Supiri Pola Credit" :
                    disputeOder.order_id.payment_type === 2 ? "Payment Method : Cash On Delivery" :
                    disputeOder.order_id.payment_type === 3 ? "Payment Method : Bank Transfer" :
                    disputeOder.order_id.payment_type === 4 ? "Payment Method : Card Payment" : "ERROR" }}
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 deladdress-blc">
                    Mobile : {{ disputeOder.order_id.shipping_mobile }}
                </div>
                <div v-if="disputeOder.order_id.secondary_mobile" class="col-lg-4 col-md-6 col-sm-6 col-xs-6 deladdress-blc">
                    Home Number : {{ disputeOder.order_id.secondary_mobile }}
                </div>
                <div v-if="!disputeOder.order_id.secondary_mobile" class="col-lg-4 col-md-4 col-sm-4 col-xs-4 deladdress-blc">
                    Home Number : Not Available
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 deladdress-blc">
                    Email : {{ disputeOder.order_id.user_id.email }}
                </div>
          </div>

          <div class="col-lg-12 col-sm-12">
            <h2 class="title">Order ID: {{ disputeOder.order_id.order_env_number }} <button style="float: right;" data-toggle="modal" data-target="#mdlDisputeChat" class="btn btn-primary">Dispute Chat</button></h2>
            <div>Date: &nbsp;{{disputeOder.created_date.split("T")[0]}}</div>
            <div>Time: &nbsp;{{created_time}}</div>
            <!-- <h4 v-if="pOSReference" class="title">POS Reference: {{ pOSReference }}</h4> -->
          </div>
      </div>
      

      <div class="row" style="margin-right: 0px;margin-left: 0px;">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-wrapper" id="tabwrapper">
        <ul ref="tablist">
          <li
            @click="activePanel('pending')"
            data-target="#pending"
            :class="{ active: true }"
          >
            In Dispute
          </li>
        </ul>
      </div>

        <div
          class="col-sm-12 panel-wrapper"
          id="panels"
          ref="panelscont"
          style="padding-bottom: 0px;"
        >
          <div id="pending" class="panel" :class="{ active: true }">
            <div class="panel-body">
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 img-col">
                  <img
                    :src="disputeOder.simple_product_id.images[0].image"
                    style="display: block;width: 100%;"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">{{ disputeOder.product_name }}</div>
                <div
                  class="col-lg-1 col-md-2 col-sm-2 col-xs-2"
                >{{ disputeOder.order_line_id.qty > 1 ? disputeOder.order_line_id.qty + " Items" : disputeOder.order_line_id.qty + " Item" }}</div>
                <div
                  class="col-lg-1 col-md-2 col-sm-2 col-xs-2"
                >Rs. {{ disputeOder.order_line_id.unit_price * disputeOder.order_line_id.qty.toFixed(2) }}</div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <span
                    v-if="disputeOder.dispute_status === 1"
                    class="badge"
                    style="background-color: rgb(241, 202, 22);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Dispute Opened</B>
                  </span>

                  <span
                    v-if="disputeOder.dispute_status === 2"
                    class="badge"
                    style="background-color: rgb(255, 163, 0); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Dispute in Progress</B>
                  </span>

                  <span
                    v-if="disputeOder.dispute_status === 10"
                    class="badge"
                    style="background-color: green ;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Merchant Approved</B>
                  </span>

                  <span
                    v-if="disputeOder.dispute_status === 20"
                    class="badge"
                    style="background-color: red ;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Merchant Rejected</B>
                  </span>

                  <span
                    v-if="disputeOder.dispute_status === 30"
                    class="badge"
                    style="background-color: rgb(163, 43, 185); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Escalated to Cochchi Admin</B>
                  </span>

                  <span
                    v-if="disputeOder.dispute_status === 31"
                    class="badge"
                    style="background-color: rgb(163, 43, 185); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Admin in Progress</B>
                  </span>

                  <span
                    v-if="disputeOder.dispute_status === 40"
                    class="badge"
                    style="background-color: green ;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Admin Approved</B>
                  </span>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <b>Reason:-</b> {{ disputeOder.dispute_reason.reason }}<br/>
                  <b>Sub Reason:-</b> {{ disputeOder.sub_dispute_reason.reason }}<br/>
                  <b>Refund Method:-</b> {{ disputeOder.refund_method === 1 ? "Bank Refund" : disputeOder.refund_method === 2 ?"Voucher" :"Credit Card Reversal"}}<br/>
                  <b  v-if="disputeOder.refund_method === 1 && disputeOder.payment_type !== 4" >Bank:-</b> {{ disputeOder.bank }}<br/>
                  <b  v-if="disputeOder.refund_method === 1 && disputeOder.payment_type !== 4" >Branch:-</b> {{ disputeOder.branch }}<br/>
                  <b  v-if="disputeOder.refund_method === 1 && disputeOder.payment_type !== 4"  >Account Number:-</b> {{ disputeOder.account_number }}<br/>
                </div>

              </div>
            </div>
            
            <span v-if="disputeOder.simple_product_id.product_id.hasOwnProperty('package_weight')">
                Package Weight: {{ (parseFloat(disputeOder.simple_product_id.product_id.package_weight) * disputeOder.order_line_id.qty).toFixed(2) }} Kg
            </span>
            
            <div class="savebtns-block" v-if="disputeOder.dispute_status === 2 && disableDsipue !== true">
               <B>Resolution Required within:- {{ calcDisputeTime(countTime, disputeOder.created_date) }}</B> <br/>
              <button  v-if="disputeOder.refund_method != 3" class="btn btn-sm merchprof-accept-sbtn" style="margin-right: 5px;" @click="haddleMerchantAcceptModalOpen()">Merchant Accept</button>
              <button class="btn btn-sm merchprof-cancel-sbtn" style="margin-right: 5px;" @click="haddleMerchantRejectModalOpen()">Merchant Reject</button>
              <button class="btn btn-sm merchprof-send-to-admin-sbtn" @click="haddleSendToAdminModalOpen()">Send To Admin</button>
            </div>
          </div>
         
        </div>
      </div>

      <div class="row well" style="margin-right: 15px;margin-left: 15px;" v-if="disputeOder.images.length > 0">
        <h4 class="title">User Upload Images</h4>
        <div v-for="(itemp, index) in disputeOder.images" :key="index" class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
          <img
            v-if="disputeOder.images != null"
            :src="itemp"
            style="display: block;width: 100%; cursor: pointer;"
            @click="haddleOpen(itemp)"
          /></div>
      </div><br/>

      <div v-if="this.disputeOder.refund_bank_ref" class="row">
        <div class="col-md-12">
          <div id="table-content" class="col-sm-12" style>
            <h4 class="title">Bank Transaction Details</h4>
            <h5>Bank ref. Number : {{this.disputeOder.refund_bank_ref}} </h5>
            <h5>Bank Slip Image :</h5>
            <a :href="this.disputeOder.refund_bank_slip" target="_blank">
              <img :src="this.disputeOder.refund_bank_slip" alt="" height="auto" width="auto">
            </a>
          </div>
        </div>
      </div><br/>
      
      <div class="row">
        <div class="col-md-12">
          <div id="table-content" class="col-sm-12" style>
            <h4 class="title">Delivery Partner Details</h4>
            <table id="example" class="table table-striped table-border-out merchdr--delpartnerdetail-tbl">
              <thead>
                <tr>
                  <th scope="" class="" style="text-align: center;">Company Name</th>
                  <th scope="" class="" style="text-align: center;">Charge</th>
                  <th scope="" class="" style="text-align: center;">Email Address</th>
                  <th scope="" class="" style="text-align: center;">Mobile Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ disputeOder.order_line_id.delivery_partner.company_name }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >Rs. {{ disputeOder.order_line_id.delivery_charge }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ disputeOder.order_line_id.delivery_partner.email }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ disputeOder.order_line_id.delivery_partner.mobile }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- rider -->
      <div class="row">
        <div class="col-md-12">
          <div id="table-content" class="col-sm-12" style>
            <h4 class="title">Delivery Rider Details</h4>
            <table id="example" class="table table-striped table-border-out merchdr--delrider-tbl">
              <thead>
                <tr>
                  <th scope="" class="" style="text-align: center;">Rider Name</th>
                  <th scope="" class="" style="text-align: center;">Mobile Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ disputeOder.deliver_name !== "" ? disputeOder.deliver_name : "-" }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ disputeOder.deliver_mobile !== "" ? disputeOder.deliver_mobile : "-" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div id="table-content" class="col-sm-12">
            <h4 class="title" style="    margin-top: 20px; margin-bottom: 20px;">Outlet Details</h4>
            <table id="example" class="table table-striped table-border-out merchdr--outletdetails-tbl">
              <thead>
                <tr>
                  <th scope="" class="" style="text-align: center;">Outlet Name</th>
                  <th scope="" class="" style="text-align: center;">Email Address</th>
                  <th scope="" class="" style="text-align: center;">Mobile Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ disputeOder.order_line_id.store.store_name }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ disputeOder.order_line_id.store.email }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ disputeOder.order_line_id.store.phone }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" style="margin-left: -30px; margin-right:-30px;">
        <div class="bottom-form">
          <!-- New -->
          <div class="row" style="margin-left: 0px;margin-right: 0px;">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
              style="padding-left: 0px;padding-right: 0px;"
            >
              <table class="table merchdr--totalvalues-tbl">
                <thead>
                  <tr>
                    <th>Items In Cart</th>
                    <th>Sub Total</th>
                    <th>Shipping and Handling Fee</th>
                    <th>Voucher/Coupon Discount</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;">
                      <h4> 1 </h4>
                    </td>
                    <td style="text-align: center;">
                      <h4>Rs. {{ disputeOder.order_line_id.unit_price * disputeOder.order_line_id.qty.toFixed(2) }}</h4>
                    </td>
                    <td style="text-align: center;">
                      <h4>Rs. {{ disputeOder.order_line_id.delivery_charge }}</h4>
                    </td>
                    <td style="text-align: center;">
                      <h4>
                        Rs. -
                        {{
                          disputeOder.order_line_id.coupon_discount_amount + disputeOder.order_id.voucher_discount_amount
                        }}
                      </h4>
                    </td>
                    <td style="text-align: center;">
                      <h4 v-if="(disputeOder.order_line_id.unit_price * disputeOder.order_line_id.qty.toFixed(2)) + (disputeOder.order_line_id.delivery_charge) - (disputeOder.order_line_id.coupon_discount_amount + disputeOder.order_id.voucher_discount_amount)  >= 0  ">Rs. {{ (disputeOder.order_line_id.unit_price * disputeOder.order_line_id.qty.toFixed(2)) + (disputeOder.order_line_id.delivery_charge) - (disputeOder.order_line_id.coupon_discount_amount + disputeOder.order_id.voucher_discount_amount) }}
                      </h4>
                      <h4  v-if="(disputeOder.order_line_id.unit_price * disputeOder.order_line_id.qty.toFixed(2)) + (disputeOder.order_line_id.delivery_charge) - (disputeOder.order_line_id.coupon_discount_amount + disputeOder.order_id.voucher_discount_amount)  < 0  ">Rs. 0.00
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- send to admin modal start -->
     <div
      style="padding-top: 3%;"
      class="modal fade"
      id="trackSendToAdminModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle">Send To Admin</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 order-list-top">
               
                <div class="form-group required">
                <label>Reason</label>

                <textarea
                  rows="10"
                  type="text"
                  placeholder="Description"
                  class="form-control"
                  style="min-height: 80px;"
                  v-model="merchant_reason"
                />
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary buyerprof-sbtn" data-dismiss="modal">Close</button>
              <button class="btn btn-primary merchprof-sbtn" @click="haddleSendToAdmin()" :disabled="btnDisableSendToAdmin">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- send to admin modal end -->
    <!-- merchant accept modal start-->
     <div
      style="padding-top: 3%;"
      class="modal fade"
      id="trackMerchantAcceptModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle">Merchant Accept</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 order-list-top">

                <div class="form-group required">
                  <label>Refund Type</label>
                  <select class="form-control select-arrow"  v-model="refund_type" @change="changeRefundType($event.target.value)">
                      <option value="0"> --- Please Select --- </option>
                      <option v-for="(item, val) in refundTypes" :key="val" :value="item._id" >{{ item.name}}</option>
                  </select>
                </div>

                <div class="form-group required" v-if="refundTypeIsShow && disputeOder.refund_method !== 1">
                  <label>Amount Type</label>
                  <select class="form-control select-arrow"  v-model="refund_amount_type" @change="changeAmountType($event.target.value)">
                      <option value=""> --- Please Select --- </option>
                      <option v-for="(item, val) in amountTypes" :key="val" :value="item._id" >{{ item.name}}</option>
                  </select>
                </div>

                <div class="form-group required" v-if="refundTypeIsShow">
                  <label>Refund Amount</label>
                  <input
                    type="number"
                    v-model="refund_amount"
                    placeholder="Refund Amount"
                    class="form-control"
                    :disabled="isDisable"
                  />
                </div>

                <div class="form-group required" v-if="refundTypeIsShow && disputeOder.payment_type !== 4 && disputeOder.refund_method === 1">
                  <label>bank Ref. Number</label>
                  <input
                    type="text"
                    v-model="refund_bank_ref"
                    placeholder="bank Ref. Number"
                    class="form-control"
                  />
                </div>
                <div class="form-group required" v-if="refundTypeIsShow && disputeOder.payment_type !== 4 && disputeOder.refund_method === 1">
                  <div
                      style="text-align: left;margin-top:10px; font-weight: 600; font-size:14px;"
                    >
                      Attach Slip here
                      <button
                        type="button"
                        class="btn proceed-btn"
                        style="font-weight: 600;"
                        @click="pickCoverImage()"
                      >
                        Upload
                      </button>
                      <!-- <input type="file" class="inputfile" id="embedpollfileinput" @change="getFile"> -->
                      <input
                        type="file"
                        style="display: none;"
                        ref="coverImage"
                        accept="image/x-png, image/jpeg"
                        @change="getImageRes()"
                      />
                      <p>
                        <span
                          style="display: inline-block;font-size: 9px;margin-right: 12px;font-weight: normal;"
                        >
                          Allowed file types:
                          <span style="display: inline-block;margin-left: 7px;"
                            >.jpg, .jpeg, .png,</span
                          >
                        </span>
                        <span
                          style="display: inline-block;font-size: 9px;font-weight: normal;"
                        >
                          Maximum upload file size:
                          <span style="display: inline-block;margin-left: 7px;"
                            >3MB</span
                          >
                        </span>
                      </p>
                    </div>
                    <div v-if="showSpinUploadImg">
                      <div class="sk-chase">
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                      </div>
                    </div>
                    <div v-if="bankSlipImage">
                      <img
                        style="margin-top:10px; width: 100%; height: 300px;"
                        slot="image"
                        :src="bankSlipImage"
                      />
                    </div>
                </div>
               
                <div class="form-group required">
                <label>Reason</label>

                <textarea
                  rows="10"
                  type="text"
                  placeholder="Description"
                  class="form-control"
                  style="min-height: 80px;"
                  v-model="merchant_reason"
                />
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary buyerprof-sbtn" data-dismiss="modal">Close</button>
              <button class="btn btn-primary merchprof-sbtn" @click="haddleMerchantAccept()" :disabled="btnDisableSendToAdmin">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- merchant accept modal end-->
    <!-- merchant reject modal start-->
    <div
      style="padding-top: 3%;"
      class="modal fade"
      id="trackMerchantRejectModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle">Cancel Order Reason</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 order-list-top">
               
                <div class="form-group required">
                <label>Reason</label>
                <select class="form-control select-arrow"  v-model="rejcet_dispute_reason" @change="changeReason($event.target.value)">
                    <option value=""> --- Please Select --- </option>
                    <option v-for="(item, val) in rejectReason" :key="val" :value="item._id" >{{ item.cancel_reason}}</option>
                </select><br/>

                <textarea
                  v-if="resonIsShow === true"
                  rows="10"
                  type="text"
                  placeholder="Description"
                  class="form-control"
                  style="min-height: 80px;"
                  v-model="merchant_reason"
                />
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary buyerprof-sbtn" data-dismiss="modal">Close</button>
              <button class="btn btn-primary merchprof-sbtn" @click="changeCancelSubmit()" >Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- merchant reject modal end-->

    <!-- User Loan Modal -->
      <div class="modal" id="mdlDisputeChat" tabindex="-1" role="dialog" style="right:0px !important; top: auto; bottom: 0px;  left:auto;">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="border-radius: 0px !important; ">
            <div class="modal-body" style="padding:0px;">
              <div class="row">
                <ChatModal v-bind:title="'Dispute Chat'" v-bind:data="this.chatdata" /> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- User Loan Modal End --> 

  </div>
</template>
<script>
import mixinProduct from "@/mixins/merchant/APIProduct";
import mixinOrder from "@/mixins/merchant/APIOrder";
import mixinDispute from "@/mixins/merchant/APIDispute";
import ChatModal from '@/components/common/chat_modal'

export default {
  mixins: [mixinProduct, mixinOrder, mixinDispute],
  components: {
    ChatModal
  },
  data: function() {
    return {
      breadcrumb: [
        {
            text: "Dispute & Return",
            href: "/merchant-dispute-list",
            active: false
        },
        {
            text: "Dispute & Return Configure",
            active: true
        }
      ],
      disputeOder: {},
      created_time:"",

      userRole: "",
      statusObj: {},
      blockLoading: true,
      relOrderId: "",
      selectPendingToChange: [],
      pos_reference: '',

      rejectReason: [],
      chatdata: [],
      resonIsShow: false,
      rejcet_dispute_reason: "",
      merchant_reason: "",

      btnDisable: true,

      // send to admin
      merchant_reason: "",
      btnDisableSendToAdmin: true,

      //bank slip
      showSpinUploadImg: false,
      image: null,

      // timer 
      countdownseconds: 0,
      time: "",
      countTime: 0,
      disableDsipue: false,

      // refund
      refundTypes: [
        // { _id: 0, name: 'No Refund' },
        { _id: 1, name: 'Partial Refund' },
        { _id: 2, name: 'Full Refund' }
      ],
      amountTypes: [
        { _id: 1, name: 'Presentage (%)' },
        { _id: 2, name: 'Value' }
      ],
      refund_type: 0,
      refund_amount_type: 2,
      refund_amount: 0,
      refund_bank_ref: null,
      // isShowRefundType
      refundTypeIsShow: false,
      isDisable: false
    };
  },
  computed: {
    bankSlipImage: function() {
      if (this.image) {
        return this.image;
      } else {
        return "";
      }
    },
  },
  watch: {
    merchant_reason: function(val) {
      if (val !== "") {
        this.btnDisableSendToAdmin = false;
      } else {
        this.btnDisableSendToAdmin = true;
      }
    },
    countdownseconds: function(val) {
      var hour = Math.floor((val /3600));
      var minutes = Math.floor((val % 3600));
      var seconds = "00";
      // var seconds = Math.floor((minutes % 60));
      this.countTime = hour + ":" + minutes + ":" + seconds;
      this.time = minutes + " min " + seconds + " secs ";
      if(val === 0){
          this.disableDsipue = true;
      } else {
          this.disableDsipue = false;
      }
      if(val==-1) {
          this.disableDsipue = true;
          this.time = 0 + " min " + 0 + " secs ";
      }
    }
    // merchant_reason: function(val) {
    //   if (val !== "") {
    //     this.btnDisable = false;
    //   } else {
    //     this.btnDisable = true;
    //   }
    // }
  },
  created() {
    this.handleBreadcrumb();

    this.handleGetProductList();
    this.haddleGetMerchatCancelReason();
    this.haddleGetSettings();
    this.fetchDisputeChat();
    let mdata = JSON.parse(localStorage.merchant_data);
    this.userRole = mdata.role;
    // this.activePanel('pending');
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    fetchDisputeChat: async function (){
      let disputeId = this.$route.params.id
      try { 
        let responce = await this.getDisputeChat(disputeId);
        this.chatdata = responce;
      } catch (error) {
        console.log(error); 
      }
    },
    sumbitChat: async function (text){
      try {
        let object = {};
        
        object.answer = text;
        if(this.chatdata.length > 0){         
          object._id =  this.chatdata[this.chatdata.length - 1]._id; 
        } else { 

          this.$swal.fire({
            position: 'top-right',
            type: 'error',
            title: "No Questions to answer",
            showConfirmButton: false,
            timer: 1500
          }) 
          return;
        }
        
        let responce = await this.postDisputeChat(object);
        this.fetchDisputeChat(); 
      } catch (error) {
        console.log(error); 
      }
    },
    handleGetProductList: async function() {
      var orderId = this.$route.params.id

      if (this.$route.params.mode === "true") {
        
        try {
          let response = await this.getDisputeOrderLinesByOrderId(orderId);
          // response.allCoupenDiscounts = 0;

          // for (let index = 0; index < response.order_line_id.length; index++) {
          //   const element = response.order_line_id[index];
          //   response.allCoupenDiscounts =+ element.coupon_discount_amount 
            
          // }
          this.disputeOder = response;
          this.created_time = moment(response.created_date).format("hh:mm A");
          // this.created_time = response.order_id.created_date.split("T")[1].split(".0")[0];
          this.blockLoading = false;
        } catch (error) {
          throw error;
        }

      } else {
        try {
          let response = await this.getDisputeOrderLinesByOrderIdParam(orderId);
          this.disputeOder = response;
      
          this.created_time = moment(response.order_id.created_date).format("hh:mm A");
          // this.created_time = response.order_id.created_date.split("T")[1].split(".0")[0];
          this.blockLoading = false;
        } catch (error) {
          throw error;
        }

      }
    },
    activePanel: function(panel) {
      const tabList = document.querySelector("#tabwrapper ul");

      var tabListItems = tabList.querySelectorAll("li");

      tabListItems.forEach(function(tab) {
        if (tab.dataset.target == "#" + panel) {
          tab.classList.add("active");
        } else {
          tab.classList.remove("active");
        }
      });

      // alert(panel);
      const panelList = document.querySelectorAll("#panels .panel");
      panelList.forEach(function(panelItem) {
        if (panelItem.id == panel) {
          panelItem.classList.add("active");
        } else {
          panelItem.classList.remove("active");
        }
      });
    },
    haddleSendToAdminModalOpen: function() {
        $("#trackSendToAdminModalCenter").modal("show");
    },
    haddleSendToAdmin: async function() {
      var statusObj = {};

      statusObj._id = this.disputeOder._id;
      statusObj.merchant_reason = this.merchant_reason;

      try {
        await this.putDisputeOderSendToAdmin(statusObj);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Status Updated Successfully",
          showConfirmButton: false,
          timer: 1500
        });
        this.handleGetProductList();
        $("#trackSendToAdminModalCenter").modal("hide");
      } catch (error) {
        throw error;
      }
    },
    haddleMerchantAcceptModalOpen: function() {
        $("#trackMerchantAcceptModalCenter").modal("show");
    },
    pickCoverImage: function() {
      this.$refs.coverImage.click();
      this.isBankTrance = true;
    },
    getImageRes: async function() {
      try {
        this.showSpinUploadImg = true;
        let fileObj = this.$refs.coverImage.files[0];
        const fd = new FormData();
        fd.append("image", fileObj);
        let response = await this.putDisputeBankImage(fd);
        if (response) {
          this.showSpinUploadImg = false;
          this.image = response.image;
        }
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Bank slip uploaded!",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        this.showSpinUploadImg = false;
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Submit failed",
          showConfirmButton: false,
          timer: 1500,
        });
        throw error;
      }
    },
    haddleMerchantAccept: async function() {
      var statusObj = {};

      statusObj._id = this.disputeOder._id;
      statusObj.merchant_reason = this.merchant_reason;
      // refund
      statusObj.refund_type = parseInt(this.refund_type);
      statusObj.refund_amount_type = parseInt(this.refund_amount_type);
      statusObj.refund_amount = parseInt(this.refund_amount);

      if (this.disputeOder.refund_method === 1 && this.disputeOder.payment_type !== 4) {
        if (this.refund_bank_ref !== null && this.bankSlipImage !== "") {
          statusObj.refund_bank_ref = this.refund_bank_ref;
          statusObj.refund_bank_slip = this.bankSlipImage;
        } else {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please Enter Bank Transaction Details",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      try {
        await this.putDisputeOderMerchantAccept(statusObj);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Status Updated Successfully",
          showConfirmButton: false,
          timer: 1500
        });
        this.handleGetProductList();
        $("#trackMerchantAcceptModalCenter").modal("hide");
      } catch (error) {
        throw error;
      }
    },
    haddleMerchantRejectModalOpen: function() {
        $("#trackMerchantRejectModalCenter").modal("show");
    },
    haddleGetMerchatCancelReason: async function() {
      try {
        let response = await this.haddleGetMerchatDisputeCancelReasonList();
        this.rejectReason = response;
      } catch (error) {
        throw error;
      }
    },
    changeReason: function(event) {

      let reasonObj = this.rejectReason.filter(function (obj) {
        return obj._id === event;
      });

      this.resonIsShow = false;

      this.rejcet_dispute_reason = "";
      this.merchant_reason = "";

      this.rejcet_dispute_reason = event;
      this.merchant_reason = reasonObj[0].cancel_reason;

      this.btnDisable = false;

      if (event && event !== "5fbb94c2a3f14a29a2506a2c") {
        this.resonIsShow = false;
        this.btnDisable = false;
      } else {
        this.resonIsShow = true;
        this.btnDisable = true;
        this.merchant_reason = "";
      }

    },
    changeCancelSubmit: async function() {
      var statusObj = {};
      statusObj._id = this.disputeOder._id;

      statusObj.merchant_reason = this.merchant_reason;
      if (this.order_cancel_reason === "5fa276166c54a27aa16d31ef") {
      } else {
        statusObj.rejcet_dispute_reason = this.rejcet_dispute_reason;
      }

      try {
        await this.putDisputeOderMerchantReject(statusObj);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Status Updated Successfully",
          showConfirmButton: false,
          timer: 1500
        });
        this.handleGetProductList();
        $("#trackMerchantRejectModalCenter").modal("hide");
      } catch (error) {
        throw error;
      }
    },
    haddleOpen: function(img) {
      window.open(img)
    },
    haddleGetSettings: async function() {
      try {
        let res = await this.getSettings();

        let merchentDisputeTime = res.merchent_dispute_time*3600;
        this.countdownCal(merchentDisputeTime);

      } catch (error) {
        throw error;
      }
    },
    countdownCal(duration){
        this.countdownseconds = duration;
       let self = this;
        var downloadTimer = setInterval(function(){
            self.countdownseconds -= 1;
            if(self.countdownseconds <= 0){
                clearInterval(downloadTimer);
            }
        }, 1000);
    },
    calcDisputeTime(dispute0penTime, updateDate) {

      var now  =  moment(new Date).format('L, H:mm:ss a');
      var then =  moment(updateDate).format('L, H:mm:ss a');

      var ms = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"));
     
      var d = moment.duration(ms);
      var difrent = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

      // calculate dispute End Time
      var newDif = moment(dispute0penTime,"HH:mm:ss").diff(moment(difrent,"HH:mm:ss"));

      let newTime;
      if (0 < newDif) {
        var newD = moment.duration(newDif);
        var newDifrent = Math.floor(newD.asHours()) + moment.utc(newDif).format(":mm:ss");

        let dif = newDifrent.split(":");
        // newTime = dif[0] + " hours " + dif[1] + " min " + dif[2] + " secs ";
        newTime = dif[0] + " hours ";
        // if (dif[0] > 0) {
        //   newTime = dif[0] + " hours ";
          
        // } else {
        //   newTime = dif[0] + " hours "+ dif[1] + " min " + dif[2] + " secs ";
        // }
        if(dif[0] === 0){
            this.disableDsipue = true;
        } else {
            this.disableDsipue = false;
        }
      } else {
        newTime = "0 hours ";
        this.disableDsipue = true;
      }
      return newTime;
    },
    changeRefundType: function(value) {
      this.isDisable = false
      this.refund_type = value;
      this.refund_amount = 0;
      // this.refund_amount_type = 2;
      if (value === '1') { // Partial ref
        this.refundTypeIsShow = true
        this.changeAmountType(this.refund_amount_type)
      } else if (value === '2') { // full ref
        this.refundTypeIsShow = true
        this.isDisable = true
        this.refund_amount = (this.disputeOder.order_line_id.unit_price * this.disputeOder.order_line_id.qty.toFixed(2));
        this.changeAmountType(this.refund_amount_type)
      } else { // no ref
        this.refundTypeIsShow = false
        this.changeAmountType(this.refund_amount_type)
        this.refund_amount = 0
      }
    },
    changeAmountType: function(value) {
      this.refund_amount_type = value;
      // this.refund_amount = 0;
      if (this.refund_type === '2' && value === '2') { // value
        this.refund_amount = (this.disputeOder.order_line_id.unit_price * this.disputeOder.order_line_id.qty.toFixed(2));
      } else if (this.refund_type === '1' && value === '1') { // %
        this.refund_amount = 0;
      } else if (this.refund_type === '2' && value === '1') {
        this.refund_amount = 100;
      }
    }
  }
};
</script>
<style>
input[type="checkbox"][readonly] {
  pointer-events: none;
}
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.product-action-style {
  min-width: 128px;
  width: 230px;
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}
.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color:#5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.head-table {
  background: white !important;
}
.table-border-out {
  border: 1px solid #ddd;
}
.merchodrlines .merchprof-sbtn:hover {
  color: #000000;
}

.merchodrlines .merchprof-sbtn {
  background:#5779ae;
  color: #ffffff;
  border: none;
}
.merchodrlines .merchprof-cancel-sbtn {
  background:#800000;
  color: white;
  border: none;
}
.merchodrlines .merchprof-cancel-sbtn:hover {
  color: #000000;
}

.merchodrlines .merchprof-accept-sbtn {
  background:green;
  color: white;
  border: none;
}
.merchodrlines .merchprof-accept-sbtn:hover {
  color: #000000;
}

.merchodrlines .merchprof-send-to-admin-sbtn {
  background:rgb(163, 43, 185);
  color: white;
  border: none;
}
.merchodrlines .merchprof-send-to-admin-sbtn:hover {
  color: #000000;
}


.tab-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  z-index: 100;
}
.tab-wrapper ul {
  display: flex;
  list-style: none;
  padding: 0px;
  flex-basis: 100%;
  flex-wrap: nowrap;
  /* margin-bottom: 5px; */
}
.tab-wrapper ul li {
  height: 35px;
  line-height: 35px;
  min-width: 130px;
  background: #ffffff;
  /* margin-right: 5px; */
  /* border-radius: 4px; */
  text-align: center;
  cursor: pointer;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}
.tab-wrapper ul li.active {
  /* background: #ffd338; */
  color: rgb(0, 91, 170);
  border-top: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 0px solid #d8d8d8;
}
.tab-wrapper ul li:hover {
  background: #eeeeee !important;
  color: #ffd338 !important;
}
.tab-wrapper h2 {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.panel-wrapper {
  display: flex;
  min-height: 200px;
  flex-basis: 95%;
  /* padding: 20px; */
  flex-wrap: wrap;
  /* border-radius: 8px; */
  /* border: 1px solid #b8b8b8; */
  align-items: flex-start;
  /* outline: 1px solid red; */
  z-index: 10;
  margin-top: -2px;
}
.panel-wrapper .panel {
  flex-basis: 100%;
  padding: 20px;
  flex-wrap: wrap;
  display: none;
  border: 1px solid #d8d8d8;
  border-radius: 0px !important;
}
.panel-wrapper .panel h3 {
  font-family: cursive;
  /* display: flex; */
  flex-basis: 100%;
}
.panel-wrapper .panel .panel-body {
  display: flex;
  flex-basis: 100%;
}
.panel-wrapper .panel .tble-panel-body {
  display: block;
}
.panel-wrapper .panel .panel-body table {
  width: 100%;
}
.panel-wrapper .panel.active {
  display: flex;
}
.savebtns-block {
  width: 100%;
  margin-top: 30px;
  text-align: right;
}
.img-col {
  display: block;
}
.img-col img {
  display: inline-block;
  width: 100%;
}
.customer-details-block {
  padding-top: 10px;
  padding-bottom: 10px;
}
.customer-details-block .innercus {
  background: #eeeeee;
}
.deladdress-blc {
  margin-top: 15px;
}
.bottom-form {
  background-color: #efefef;
  margin: 10px 0px -10px 0px;
  padding-top: 10px;
}
.merchdr--totalvalues-tbl{
  width: 100% !important;
}
.merchdr--outletdetails-tbl{
  width: 100% !important;
}
.merchdr--delrider-tbl{
   width: 100% !important;
}
.merchdr--delpartnerdetail-tbl{
  width: 100% !important;
}
.merch-dr--tab-panel-wrapper{
  width: 1000px;
}
.merch-dr--tab-content{
  overflow-x: scroll;
}

@media screen and (max-width: 992px) {
  .merchdr--delpartnerdetail-tbl th{
    font-size: 8px;
  }
  .merchdr--delpartnerdetail-tbl td{
    font-size: 8px;
  }
  .merchdr--totalvalues-tbl th{
    font-size: 8px;
  }
  .merchdr--totalvalues-tbl td h4{
    font-size: 8px;
  }
  .merchdr--outletdetails-tbl th{
    font-size: 8px;
  }
   .merchdr--outletdetails-tbl td{
    font-size: 8px;
  }

}

@media screen and (max-width: 475px) {
  .merchdr--delpartnerdetail-tbl th{
    font-size: 7px;
  }
  .merchdr--delpartnerdetail-tbl td{
    font-size: 7px;
  }
  .merchdr--outletdetails-tbl th{
    font-size: 7px;
  }
   .merchdr--outletdetails-tbl td{
    font-size: 7px;
  }
  .merchdr--totalvalues-tbl th{
    font-size: 7px;
  }
  .merchdr--totalvalues-tbl td h4{
    font-size: 7px;
  }

}

@media screen and (max-width: 425px) {
  .merchdr--delpartnerdetail-tbl th{
    font-size: 5px;
  }
  .merchdr--delpartnerdetail-tbl td{
    font-size: 5px;
  }
  .merchdr--delrider-tbl th{
    font-size: 7px;
  }
  .merchdr--delrider-tbl td{
    font-size: 7px;
  }

}


@media screen and (max-width: 375px) {
  .merchdr--delpartnerdetail-tbl th{
    font-size: 5px;
  }
  .merchdr--delpartnerdetail-tbl td{
    font-size: 5px;
  }
  .merchdr--outletdetails-tbl th{
    font-size: 5px;
  }
   .merchdr--outletdetails-tbl td{
    font-size: 5px;
  }
  .merchdr--delrider-tbl th{
    font-size: 6px;
  }
  .merchdr--delrider-tbl td{
    font-size: 6px;
  }

}

@media screen and (max-width: 330px) {
  .merchdr--delpartnerdetail-tbl th{
    font-size: 4px;
  }
  .merchdr--delpartnerdetail-tbl td{
    font-size: 4px;
  }

}

</style>
